import React from 'react';
import {
  CodeSnippet,
  List,
  Paragraph,
  PlatformTab,
  Section,
  SectionSubhead,
  PropList,
  PropListItem,
  PageNavigation,
  PageNavigationLink,
} from 'docComponents';
import { Text } from 'hudl-uniform-ui-components';

const WebTab = () => {
  return (
    <PlatformTab>
      <PageNavigation>
        <PageNavigationLink>Switcher</PageNavigationLink>
        <PageNavigationLink>Color</PageNavigationLink>
        <PageNavigationLink>Background Color</PageNavigationLink>
        <PageNavigationLink>Border Color</PageNavigationLink>
      </PageNavigation>

      <Section title="Switcher">
        <Paragraph>
          The theme switcher serves as the underlying mixin used for other theme
          mixins. It also allows you to theme css properties not currently
          covered by the other specific theme mixins below.
        </Paragraph>

        <CodeSnippet
          code="@include theme-switchter($light, $dark, $property);"
          platform="css"
          disableCodeEditing
          gitHubLink="/mixins/_mix.theme.scss#L11"
        />

        <Section>
          <SectionSubhead>Parameters</SectionSubhead>
          <PropList>
            <PropListItem name="$light" types={['color']}>
              <Text>
                Default value: <code>null</code>
              </Text>
              <Text>The color for light environments.</Text>
            </PropListItem>

            <PropListItem name="$dark" types={['color']}>
              <Text>
                Default value: <code>null</code>
              </Text>
              <Text>The color for dark environments.</Text>
            </PropListItem>

            <PropListItem name="$property" types={['css property']}>
              <Text>
                Default value: <code>null</code>
              </Text>
              <Text>
                The CSS property you want the $light and $dark paramenters to
                apply.
              </Text>
            </PropListItem>
          </PropList>
        </Section>
      </Section>
      <Section title="Color">
        <CodeSnippet
          code="@include theme-color($light, $dark, $auto-switch);"
          platform="css"
          disableCodeEditing
          gitHubLink="/mixins/_mix.theme.scss#L41"
        />

        <Section>
          <SectionSubhead>Parameters</SectionSubhead>
          <PropList>
            <PropListItem name="$light" types={['color']}>
              <Text>
                Default value: <code>$color-le-text-default</code>
              </Text>
              <Text>The type color for light environments.</Text>
            </PropListItem>

            <PropListItem name="$dark" types={['color']}>
              <Text>
                Default value: <code>null</code>
              </Text>
              <Text>The the color for dark environments.</Text>
            </PropListItem>

            <PropListItem name="$auto-switch" types={['bool']}>
              <Text>
                Default value: <code>true</code>
              </Text>
              <Text>
                Setting to false opts out of auto switching based on $light
                argument. Set this to false when you want to customize the
                switch between light and dark.
              </Text>
            </PropListItem>
          </PropList>

          <div className="uni-margin--two--top">
            <SectionSubhead>Notes</SectionSubhead>
            <List type="unordered">
              <li>
                The <code>auto-switch</code> is configured to automatically
                choose the appropriate dark environment color, if you have
                passed in one of the <code>$color-le-text-</code> variables.
              </li>
            </List>
          </div>
        </Section>
      </Section>
      <Section title="Background Color">
        <Paragraph>
          The theme background mixin is used to switch the background color of
          an element based on whether its in a dark or light environment.
        </Paragraph>
        <CodeSnippet
          code="@include theme-bg($light, $dark);"
          platform="css"
          disableCodeEditing
          gitHubLink="/mixins/_mix.theme.scss#L77"
        />

        <Section>
          <SectionSubhead>Parameters</SectionSubhead>
          <PropList>
            <PropListItem name="$light" types={['color']}>
              <Text>
                Default value: <code>null</code>
              </Text>
              <Text>The background color for light environments..</Text>
            </PropListItem>

            <PropListItem name="$dark" types={['color']}>
              <Text>
                Default value: <code>null</code>
              </Text>
              <Text>The background color for dark environments.</Text>
            </PropListItem>
          </PropList>
        </Section>
      </Section>
      <Section title="Border Color">
        <CodeSnippet
          code="@include theme-bg($light, $dark, $auto-switch);"
          platform="css"
          disableCodeEditing
          gitHubLink="/mixins/_mix.theme.scss#L108"
        />

        <Section>
          <SectionSubhead>Parameters</SectionSubhead>
          <PropList>
            <PropListItem name="$light" types={['color']}>
              <Text>
                Default value: <code>null</code>
              </Text>
              <Text>The border color for light environments.</Text>
            </PropListItem>

            <PropListItem name="$dark" types={['color']}>
              <Text>
                Default value: <code>null</code>
              </Text>
              <Text>The border color for dark environments.</Text>
            </PropListItem>

            <PropListItem name="$auto-switch" types={['bool']}>
              <Text>
                Default value: <code>true</code>
              </Text>
              <Text>Turns auto switching off or on.</Text>
            </PropListItem>
          </PropList>

          <div className="uni-margin--two--top">
            <SectionSubhead>Notes</SectionSubhead>
            <List type="unordered">
              <li>
                The <code>auto-switch</code> is configured to automatically
                choose <code>$color-de-divider</code>, if you have passed{' '}
                <code>$color-le-divider</code> for the <code>$light</code>{' '}
                parameter.
              </li>
            </List>
          </div>
        </Section>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
